@font-face {
    font-family: 'Barlow Light';
    src: url('fonts/barlow/Barlow-Light.ttf')  format('truetype'); /* Safari, Android, iOS */
}
@font-face {
    font-family: 'Barlow Regular';
    src: url('fonts/barlow/Barlow-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
}
@font-face {
    font-family: 'Barlow Bold';
    src: url('fonts/barlow/Barlow-Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
}

/*todo - fonts for belong?*/
@font-face {
    font-family: 'Mastercard';
    src: url("http://localhost:3000/assets/fonts/Mark/MarkForMC-Bold.ttf") format('truetype'); /* TODO: SWAP OUT http://localhost:3000 FOR https://mastercard-global-dei-summit.zonevs.io BEFORE DEPLOY!!!! */
}

/*todo - fonts for belong?*/
@font-face {
    font-family: 'MastercardBold';
    src: url("http://localhost:3000/assets/fonts/Mark/MarkForMC-Black.ttf") format('truetype'); /* TODO: SWAP OUT http://localhost:3000 FOR https://mastercard-global-dei-summit.zonevs.io BEFORE DEPLOY!!!! */
}

/*todo - fonts for belong?*/
@font-face {
    font-family: 'MastercardItalicBold';
    src: url("http://localhost:3000/assets/fonts/Mark/MarkForMC-BlackIt.ttf") format('truetype'); /* TODO: SWAP OUT http://localhost:3000 FOR https://mastercard-global-dei-summit.zonevs.io BEFORE DEPLOY!!!! */
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/*.custom-text-Barlow-Regular {*/
/*    font-family: 'Barlow Regular';*/
/*    font-size: 128px;*/
/*    line-height: 128px;*/
/*    color: white;*/
/*}*/
/*.custom-text-Barlow-Regular-half {*/
/*    font-family: 'Barlow Regular';*/
/*    font-size: 42;*/
/*    line-height: 44px;*/
/*    color: white;*/
/*}*/
.custom-text-Barlow-Regular-small-upper {
    font-family: 'Barlow Regular';
    font-size: 70px;
        line-height: 0px;
    color: white;
}
.custom-text-Barlow-Regular-dialogBox {
    font-family: 'Barlow Regular';
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
}

.custom-text-Barlow-Regular-dialogBox-small {
    font-family: 'Barlow Regular';
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
}

.custom-text-Barlow-Regular-dialogBoxHeadline {
    font-family: 'Barlow Regular';
    font-size: 24px;
    line-height: 24px;
    color: #ffffff;
}

/*.custom-text-Barlow-Regular-small {*/
/*    font-family: 'Barlow Regular';*/
/*    font-size: 48px;*/
/*    !*line-height: 70px;*!*/
/*    color: white;*/
/*}*/

#outer {
    text-align: center;
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}

#home-top-video:before {
    content:"";
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    z-index:1;


    /*commented out for holding page*/
    background:linear-gradient(to top, rgba(18, 19, 49, 0.15), rgba(18, 19, 49, 1.0));

    /*background:linear-gradient(to top, rgb(18, 19, 49), rgba(2, 2, 2, 0.51));*/

}

#home-top-video {
    left: 0%;
    top: 0%;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 0;
}

.App-bgvideo {
    /*opacity: 0;*/
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
}

.viewport-header {
    position: relative;
    height: 100vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.5s; /* Firefox < 16 */
    -ms-animation: fadein 0.5s; /* Internet Explorer */
    -o-animation: fadein 0.5s; /* Opera < 12.1 */
    animation: fadein 0.5s;
}

@keyframes fadein {
    from {opacity:0;}
    to {opacity:1;}
}


/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes skip {
    from { opacity: 0; }
    to   { opacity: 1; }
}
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.customOverlay {
    background: rgba(36, 123, 160, 0.7);
}
.customModal {
    background: #b2dbbf;
    /*max-width: 500px;*/
    /*width: 100%;*/
}




.click-region-outer {
    /* wrapper component to fix layout issues with scrollbars breaking alignment of home image overlay */
    position: relative;
    width: 100vw;
    height: 100vh;
    display: grid;
    align-items: center;
    overflow: hidden;
}

.click-region-inner {
    position: relative;
    margin: 0 auto;
    max-height: 100vh;
    max-width: 177.777vh;
}

.click-region {
    margin: 0 auto;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.image-map__content {
    position: relative !important;
}

.click-region span {
    cursor: pointer;
    border: solid 2px rgba(0, 170, 231, 0.0);
    border-radius: 10%;
    /*box-shadow: 120px 80px 40px 20px #0ff;*/
}

.click-region span:hover {
    /*cursor: pointer;*/
    /*border: solid 5px blue;*/
    /*background-color: rgba(25,200,0,0.33);*/

    width: 0px;
    height: 0px;
    background: rgba(167, 255, 104, 0.0);
    border-radius: 1%;
    box-shadow: 0px 0px 20px 20px rgba(0, 170, 231, 0.66);
    /* in order: x offset, y offset, blur size, spread size, color */
    /* blur size and spread size are optional (they default to 0) */


}

.image-click-region:hover {
    box-shadow: 0px 0px 10px 10px rgba(0, 170, 231, 0.66);
}

